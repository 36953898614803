// Import Views
import MemberView from './MemberView.js';

// Import Datas
import membersData from './member.json';

// eslint-disable-next-line import/no-unresolved
import * as memberImages from './images/*.jpg';

// memberController
const memberController = {
    memberView: null,

    init() {
        this.memberView = new MemberView(membersData, memberImages);
        this.callMemberView();
    },

    callMemberView() {
        this.memberView.createMember();
    },
};

// Launch the memberController
const launchMemberController = () => {
    memberController.init();
};

launchMemberController();
