const _temp0 = require("./images/1.jpg");
const _temp1 = require("./images/2.jpg");
const _temp2 = require("./images/3.jpg");
const _temp3 = require("./images/4.jpg");
const _temp4 = require("./images/5.jpg");
const _temp5 = require("./images/6.jpg");
module.exports = {
  "1": _temp0,
  "2": _temp1,
  "3": _temp2,
  "4": _temp3,
  "5": _temp4,
  "6": _temp5
}