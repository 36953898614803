export default class MemberView {
    constructor(membersData, memberImages) {
        this.membersData = membersData;
        this.memberImages = memberImages;
        this.teamElement = document.querySelector('[data-id="members"]');
    }

    createMember() {
        this.membersData.members.forEach((member) => {
            const figureElement = document.createElement('figure');
            figureElement.setAttribute('class', 'member');

            const imgElement = document.createElement('img');
            imgElement.setAttribute('class', 'member__image');
            imgElement.src = this.memberImages[member.fileName];
            imgElement.alt = member.alt;

            const figcaptionElement = document.createElement('figcaption');
            figcaptionElement.setAttribute('class', 'member__caption');
            figcaptionElement.textContent = `${member.firstName} ${member.lastName} - ${member.job}`;

            const paragraphElement = document.createElement('p');
            paragraphElement.setAttribute('class', 'member__description');
            paragraphElement.innerHTML = member.description;

            figureElement.appendChild(imgElement);
            figureElement.appendChild(figcaptionElement);
            figureElement.appendChild(paragraphElement);
            this.teamElement.appendChild(figureElement);
        });
    }
}
