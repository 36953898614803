{
    "members": [
        {
            "fileName": "3",
            "firstName": "Marie",
            "lastName": "Mercier",
            "job": "Analyste en santé environnementale",
            "description": "\"Bonjour, je m'appelle Marie, je suis analyste en santé environnementale. Mon rôle est de veiller à ce que chaque produit présenté sur <strong>Santé-Éco-Logis</strong> soit sans danger pour votre santé et celle de vos proches. Avec une formation en sciences environnementales et en toxicologie, je passe au crible les composants et les procédés de fabrication pour vous garantir des choix sûrs et sains. Ma mission est de vous offrir la tranquillité d'esprit en sachant que les produits que vous utilisez au quotidien sont sans risque.\"",
            "alt": "membre-3"
        },
        {
            "fileName": "1",
            "firstName": "Simon",
            "lastName": "Leroy",
            "job": "Designer",
            "description": "\"Je m'appelle Simon, le Designer de <strong>Santé-Éco-Logis</strong>. Avec un parcours en design d'intérieur et une forte sensibilité aux enjeux environnementaux, je me consacre à vous proposer des idées de décoration et d'aménagement durable. Mon travail consiste à allier esthétique et éco-responsabilité pour vous aider à créer des espaces de vie beaux et respectueux de la planète.\"",
            "alt": "membre-1"
        },
        {
            "fileName": "2",
            "firstName": "Maxime",
            "lastName": "Girard",
            "job": "Développeur web",
            "description": "\"Moi c'est Maxime et je suis Développeur Web de <strong>Santé-Éco-Logis</strong>. Passionné par le code et l'écologie, je suis en charge de créer et d'optimiser <strong>Santé-Éco-Logis</strong> pour une expérience utilisateur fluide et agréable. Je veille à ce que notre plateforme soit rapide, sécurisée et accessible à tous.\"",
            "alt": "membre-2"
        },
        {
            "fileName": "4",
            "firstName": "May",
            "lastName": "Perrin",
            "job": "Chargée de contenu",
            "description": "\"Bonjour à tous ! Je m'appelle May et je suis chargée de contenu sur <strong>Santé-Éco-Logis</strong>. Ma passion ? Partager des idées et des astuces pour rendre chaque habitat plus écologique et sain. Avec un diplôme en journalisme et une spécialisation en développement durable, j'adore créer des articles et des guides qui inspirent nos lecteurs à faire des choix respectueux de l'environnement. Mon objectif est de vous fournir des informations précises et des conseils pratiques pour un mode de vie plus vert qui soit respectueux de votre santé et de celle des autres.\"",
            "alt": "membre-4"
        },
    ]
}
